import React, {useState, useEffect} from 'react';
import { Select } from 'antd';
import style from './style.module.css';
import PropTypes from 'prop-types';

const {Option} = Select;

const getWidth = () => window.innerWidth 
  || document.documentElement.clientWidth 
  || document.body.clientWidth;

const SelectLocation = ({ onSelect, options, value, fontColor }) => {
    let [width, setWidth] = useState(getWidth());

    useEffect(() => {
        // timeoutId for debounce mechanism
        let timeoutId = null;
        const resizeListener = () => {
          // prevent execution of previous setTimeout
          clearTimeout(timeoutId);
          // change width from the state object after 150 milliseconds
          timeoutId = setTimeout(() => setWidth(getWidth()), 150);
        };
        // set resize listener
        window.addEventListener('resize', resizeListener);
    
        // clean up function
        return () => {
          // remove resize listener
          window.removeEventListener('resize', resizeListener);
        }
      }, [])

    return (
        <div className={style.selectMenuWrapper} id="area">
            {(width < 520) ?  
                    <select value={value} className={style.selectLocatiSelector} name='location selector' onChange = {(e)=>onSelect(e.target.value)}>
                        {options.map(option => {
                        return (
                            <option key= {option.id} value={option.id} className={style.optionsWrapper}>
                                {option.title} - {option.locationId}
                            </option> ) 
                        })}
                </select> :
            <Select
                value={value}
                placeholder='Select location'
                className={style.filterLocations}
                style={{color: fontColor}}
                onSelect={onSelect}
                dropdownMatchSelectWidth
                getPopupContainer={() => document.getElementById('area')}
            >
                {options.map(option => {
                    return (
                            <Option key={option.id} value={option.id} className={style.selectLocationStyle}  style={{color: fontColor}}>
                                {option.title}-{option.locationId}
                            </Option>
                    )}
                )}
            </Select>
                }
            
               
        </div>
    );
};

SelectLocation.propTypes = {
    onSelect: PropTypes.func,
    options: PropTypes.array,
    value: PropTypes.number, 
    fontColor: PropTypes.string,
}

export default SelectLocation;