import React from 'react';
import { ReactComponent as TripadvisorIcon } from '../assets/images/tripadvisor.svg';
import { ReactComponent as FoursquareIcon } from '../assets/images/foursquare.svg';
import { ReactComponent as FacebookIcon } from '../assets/images/facebook.svg';
import { ReactComponent as GoogleIcon } from '../assets/images/google.svg';
import { ReactComponent as ZomatoIcon } from '../assets/images/zomato.svg'
import { ReactComponent as TalabatIcon } from '../assets/images/talabat.svg';
import { ReactComponent as ReserveoutIcon } from '../assets/images/reserveout.svg';
import { ReactComponent as HSIcon } from '../assets/images/hungerStation.svg';
import { ReactComponent as AgodaIcon } from '../assets/images/agoda.svg';
import { ReactComponent as AvvoIcon } from '../assets/images/avvo.svg';

import { ReactComponent as GoogleplaystoreIcon } from '../assets/images/google shopping.svg';
import { ReactComponent as YelpIcon } from '../assets/images/yelp.svg';


import { ReactComponent as Googleshoppingicon } from '../assets/images/google shopping.svg';
import { ReactComponent as ZillowIcon } from '../assets/images/zillow.svg';
import { ReactComponent as AirbnbIcon } from '../assets/images/Airbnb.svg';

import { ReactComponent as AliExpressIcon } from '../assets/images/aliexpress.svg'
import { ReactComponent as AlternativetoIcon } from '../assets/images/AlternativeTo.svg';
import { ReactComponent as AmazonIcon } from '../assets/images/Amazon.svg';
import { ReactComponent as ApartmentratingsIcon } from '../assets/images/ApartmentRatings.svg';
import { ReactComponent as ApartmentsIcon } from '../assets/images/Apartments.svg';
import { ReactComponent as AppleappstoreIcon } from '../assets/images/AppStore.svg';

import { ReactComponent as BbbIcon } from '../assets/images/BBB.svg'
import { ReactComponent as BookTableIcon } from '../assets/images/bookatable.svg';
import { ReactComponent as BookingIcon } from '../assets/images/talabat.svg';//-

import { ReactComponent as CapterraIcon } from '../assets/images/capterra.svg';
import { ReactComponent as CargurusIcon } from '../assets/images/Cargurus.svg';
import { ReactComponent as CarscomIcon } from '../assets/images/cars.com.svg';
import { ReactComponent as CitysearchIcon } from '../assets/images/citysearch.svg';


import { ReactComponent as ClasspassIcon } from '../assets/images/classpass.svg'
import { ReactComponent as ConsumeraffairsIcon } from '../assets/images/consumeraffairs.svg';
import { ReactComponent as CreditkarmaIcon } from '../assets/images/creditkarma.svg';
import { ReactComponent as CustomerlobbyIcon } from '../assets/images/customerlobby.svg';
import { ReactComponent as DealerraterIcon } from '../assets/images/dealerrater.svg';
import { ReactComponent as EbayIcon } from '../assets/images/ebay.svg';
import { ReactComponent as ExpediaIcon } from '../assets/images/avvo.svg';//-


import { ReactComponent as EdmundsIcon } from '../assets/images/edmunds.svg'
import { ReactComponent as EtsyIcon } from '../assets/images/etsy.svg';
import { ReactComponent as FindlawIcon } from '../assets/images/FindLaw.svg';
import { ReactComponent as G2crowdIcon } from '../assets/images/g2crowd.svg';
import { ReactComponent as GartnerIcon } from '../assets/images/gartner.svg';
import { ReactComponent as GlassdoorIcon } from '../assets/images/glassdoor.svg';


import { ReactComponent as GreatschoolsIcon } from '../assets/images/greatschools.svg'
import { ReactComponent as HealthgradesIcon } from '../assets/images/healthgrades.svg';
import { ReactComponent as HomeadvisorIcon } from '../assets/images/homeadvisor.svg';
import { ReactComponent as HomeawayIcon } from '../assets/images/homeaway.svg';
import { ReactComponent as HomestarsIcon } from '../assets/images/homestars.svg';
import { ReactComponent as HouzzIcon } from '../assets/images/houzz.svg';

import { ReactComponent as HotelscomIcon } from '../assets/images/homestars.svg'
import { ReactComponent as IndeedIcon } from '../assets/images/indeed.svg';
import { ReactComponent as InfluensterIcon } from '../assets/images/influenster.svg';
import { ReactComponent as InsiderpagesIcon } from '../assets/images/insiderpages.svg';
import { ReactComponent as ItcentralstationIcon } from '../assets/images/itcentralstation.svg';
import { ReactComponent as LawyerscomIcon } from '../assets/images/lawyers.svg';

import { ReactComponent as LendingtreeIcon } from '../assets/images/lendingtree.svg'
import { ReactComponent as MartindaleIcon } from '../assets/images/martindale.svg';
import { ReactComponent as NicheIcon } from '../assets/images/niche.svg';
import { ReactComponent as OpenriceIcon } from '../assets/images/openrice.svg';
import { ReactComponent as OpentableIcon } from '../assets/images/opentable.svg';
import { ReactComponent as ProducthuntIcon } from '../assets/images/product-hunt.svg';


import { ReactComponent as ProductreviewcomauIcon } from '../assets/images/ProductReview.svg'
import { ReactComponent as RatemdsIcon } from '../assets/images/rateMDs.svg';
import { ReactComponent as SitejabberIcon } from '../assets/images/sitejabber.svg';
import { ReactComponent as SoftwareadviceIcon } from '../assets/images/software advice.svg';
import { ReactComponent as TheknotIcon } from '../assets/images/theknot.svg';
import { ReactComponent as ThumbtackIcon } from '../assets/images/thumbtack.svg';

import { ReactComponent as TruliaIcon } from '../assets/images/trulia.svg'
import { ReactComponent as TrustedshopsIcon } from '../assets/images/trustedshops.svg';
import { ReactComponent as TrustpilotIcon } from '../assets/images/trustpilot.svg';
import { ReactComponent as TrustradiusIcon } from '../assets/images/trustradius.svg';
import { ReactComponent as VitalsIcon } from '../assets/images/vitals.svg';
import { ReactComponent as WalmartIcon } from '../assets/images/walmart.svg';

import { ReactComponent as WeddingwireIcon } from '../assets/images/weddingwire.svg';
import { ReactComponent as YellIcon } from '../assets/images/yell.svg';
import { ReactComponent as YellowpagesIcon } from '../assets/images/yellow-pages.svg';
import { ReactComponent as ZocdocIcon } from '../assets/images/zocdoc.svg';


const SOURCES_ICONS = {
  google: GoogleIcon,
  tripadvisor: TripadvisorIcon,
  foursquare: FoursquareIcon,
  facebook: FacebookIcon,
  zomato: ZomatoIcon,
  talabat: TalabatIcon,
  hungerStation: HSIcon,
  hungerstation: HSIcon,
  reserveout: ReserveoutIcon,
  agoda: AgodaIcon,
  avvo: AvvoIcon,
  googleplaystore: GoogleplaystoreIcon,
  googleshopping: Googleshoppingicon,
  yelp: YelpIcon,
  zillow: ZillowIcon,
  airbnb: AirbnbIcon,
  aliexpress: AliExpressIcon,
  alternativeto: AlternativetoIcon,
  amazonus: AmazonIcon,
  amazonfrance: AmazonIcon,
  apartmentratings: ApartmentratingsIcon,
  apartments: ApartmentsIcon,
  appleappstore: AppleappstoreIcon,
  bbb: BbbIcon,
  bookatable: BookTableIcon,
  booking: BookingIcon,
  capterra: CapterraIcon,
  cargurus: CargurusIcon,
  carscom: CarscomIcon,
  citysearch: CitysearchIcon,
  classpass: ClasspassIcon,
  consumeraffairs: ConsumeraffairsIcon,
  creditkarma: CreditkarmaIcon,
  customerlobby: CustomerlobbyIcon,
  dealerrater: DealerraterIcon,
  ebay: EbayIcon,
  edmunds: EdmundsIcon,
  etsy: EtsyIcon,
  expedia: ExpediaIcon,
  findlaw: FindlawIcon,
  g2crowd: G2crowdIcon,
  gartner: GartnerIcon,
  glassdoor: GlassdoorIcon,
  greatschools: GreatschoolsIcon,
  healthgrades: HealthgradesIcon,
  homeadvisor: HomeadvisorIcon,
  homeaway: HomeawayIcon,
  homestars: HomestarsIcon,
  houzz: HouzzIcon,
  hotelscom: HotelscomIcon,
  indeed: IndeedIcon,
  influenster: InfluensterIcon,
  insiderpages: InsiderpagesIcon,
  itcentralstation: ItcentralstationIcon,
  lawyerscom: LawyerscomIcon,
  lendingtree: LendingtreeIcon,
  martindale: MartindaleIcon,
  niche: NicheIcon,
  openrice: OpenriceIcon,
  opentable: OpentableIcon,
  producthunt: ProducthuntIcon,
  productreviewcomau: ProductreviewcomauIcon,
  ratemds: RatemdsIcon,
  sitejabber: SitejabberIcon,
  softwareadvice: SoftwareadviceIcon,
  theknot: TheknotIcon,
  thumbtack: ThumbtackIcon,
  trulia: TruliaIcon,
  trustedshops: TrustedshopsIcon,
  trustpilot: TrustpilotIcon,
  trustradius: TrustradiusIcon,
  vitals: VitalsIcon,
  walmart: WalmartIcon,
  weddingwire: WeddingwireIcon,
  yell: YellIcon,
  yellowpages: YellowpagesIcon,
  zocdoc: ZocdocIcon,
}

const sourseIcon = (source, fill = '#5DBCD2', width, height) => {
  const iconWithoutSpaces = source.toLowerCase().split(' ').join('').split('.').join('');
  const Icon = SOURCES_ICONS[iconWithoutSpaces];
  !SOURCES_ICONS[iconWithoutSpaces] && console.log(iconWithoutSpaces, source);
  return SOURCES_ICONS[iconWithoutSpaces] && <Icon fill={fill} width={width} height={height} />
};
export {
  sourseIcon
};