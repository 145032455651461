import React from 'react';
import style from './style.module.css';
import { Card, Typography } from 'antd';
import moment from 'moment';
import mobileLogo from '../../assets/images/mobile-sample-logo.png';
import Logo from '../../assets/images/Localyser_Logo_Primary.png';

const { Title } = Typography;

const Coupon = ({ coupon }) => {
  
  const formattedDate = moment(coupon.expireDate, 'YYYY-MM-DD').format('DD/MM/YYYY');

  const checkIfExpired = (expireDate) => {
    const currentDate = moment();
    const couponDate = moment(expireDate, 'YYYY-MM-DD');
    return currentDate.isAfter(couponDate);
  };

  let expiredInfo = '';
  if (coupon.expireDate){
    expiredInfo = checkIfExpired(coupon.expireDate) ? 'This offer has expired' : `Expires on ${formattedDate}`;
  }

  return (
    <div className={style.couponCard}>
      <div className={style.couponCardWrap}>
        <Card className={style.couponsInfo}>
          <div className={style.storeLogo}>
            <img src={mobileLogo} alt={`${'storeName'} logo`} className={style.logoImage} />
          </div>
          <Title level={3} className={style.storeName}>
            {coupon.companyName}
          </Title>
          <Title level={4} className={style.discountText}>
            {coupon.offerName}
          </Title>
          <div className={style.couponsInfoWrap}>
            <div className={style.couponCodeWrap}>
              <div className={style.couponCodeLabel}>Your coupon is:</div>
              <div className={style.couponCode}>{coupon.code}</div>
            </div>
            <div className={style.expireDate}>{expiredInfo}</div>
          </div>
        </Card>
        <div className={style.poweredByWrapper}>
          <p className={style.poweredBy}>
            Powered by &#160;
            <a href="https://www.localyser.com" target="_blank" rel="noopener noreferrer">
              <img src={Logo} alt="Localyser logo" width="72px" />
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Coupon;
