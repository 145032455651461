import React from 'react';
import style from './style.module.css';


const LocationLogoAndAddress = ({ logoUrl, companyAdress, companyName, fontColor }) => {
    return (
        <>
            <img  src={logoUrl} alt="Company logo" style = {{height: 'auto', width: '150px', borderRadius: '5px'}}/>
            {companyName && <h2 className={style.companyTitle} style={{color: fontColor }}>{companyName}</h2>}
            {companyAdress && <p  className={style.companyAdress} style={{color: fontColor }}>{companyAdress}</p>}
        </>
    );
};

export default LocationLogoAndAddress;