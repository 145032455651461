import React from 'react';
// import { Button } from 'antd';
import style from './style.module.css';
// import Details from '../Details';

import checkIcon from '../../assets/images/check-circle.png';

const NegativeFeedbackPage = ({
  // userFeedbackPlaceholder,
  // userDetailsFeedbackTitle,
  // submitButtonTitle,
  // sendNegativeFeddbackDetails,
  backgroundColor,
  thanksForFeedback,
  sorryMessage,
  sorryMessageWithoutContacts,
  // learnMore,
  // giveMoreDetailsButton,
  // submitButton,
  fontColor,
  // submitValidationMessage,
  userFeedback,
  // thanksMessage
}) => {
  const hasContactInfo = userFeedback.customerInformation?.mobile?.value || userFeedback.customerInformation?.email?.value;
  //const [showMoreDetails, setshowMoreDetails] = useState(false);
  // const [detailsValue, setDetailsValue] = useState('');
  // const [detailsvalueError, setDetailsvalueError ] = useState('');
  // const [showThanksMessage, setShowMessage] = useState(false);

  // const onTogleShowDetails = ()=> {
  //     setshowMoreDetails(true)
  // }

  //   const onChange =(e)=> {
  //      setDetailsValue( e.currentTarget.value);
  //      if (detailsvalueError) {
  //         setDetailsvalueError('');
  //      }
  //   }

  //   const onSubmit = async (e) => {
  //       e.preventDefault()
  //       if (detailsValue) {
  //           await sendNegativeFeddbackDetails(detailsValue)
  //           setDetailsValue('');
  //           setShowMessage(true);
  //         }
  //         else {
  //             setDetailsvalueError(submitValidationMessage)
  //         }
  //   }
  return (
    <div className={style.negativeFeedbackPageAndEditorWrapper}>
      <div className={style.pageWrapper} style={{ backgroundColor: backgroundColor }}>
        <img src={checkIcon} alt="Check icon" />

        <p className={style.thanksForFeedback} style={{ color: fontColor }}>
          {thanksForFeedback}
        </p>

        {hasContactInfo ? (
            <p className={style.sorryMessage} style={{ color: fontColor }}>
              {sorryMessage}
            </p>
        ) : (
          <p className={style.sorryMessage} style={{ color: fontColor }}>
            {sorryMessageWithoutContacts}
          </p>
        )}

        {/* { !showMoreDetails  && !userFeedback.userFeedback &&
         <>
         <p className={style.learnMore} style={{color: fontColor}}>{learnMore}</p>
                    <Button type="primary" className={style.detailsButton} onClick={onTogleShowDetails}>
                        {giveMoreDetailsButton}
                    </Button>
                </>
                }
                {showMoreDetails
                    &&
                    <Details
                            detailsValue={detailsValue}
                            onChange={onChange}
                            onSubmit = {onSubmit}
                            userDetailsFeedbackTitle ={userDetailsFeedbackTitle}
                            userFeedbackPlaceholder = {userFeedbackPlaceholder}
                            submitButtonTitle={submitButtonTitle}
                            detailsvalueError={detailsvalueError}
                            submitButton={submitButton}
                            fontColor={fontColor}
                            showThanksMessage={showThanksMessage}
                            showMoreDetails={showMoreDetails}
                            thanksMessage={thanksMessage}
                            />
                } */}
      </div>
    </div>
  );
};

export default NegativeFeedbackPage;
