import React from 'react';
import LanguageFilter from '../LanguageFilter';

import style from './style.module.css';


const RequestHeader = ({ headerTitle, languagesOptions, value, onSelect }) => {
    return (
        <div className={style.headerWrapper}>
            <div className={style.header}>
                <h1 className={style.title}>{headerTitle}</h1>
                <LanguageFilter onSelect={onSelect} value={value} languagesOptions={languagesOptions} />
            </div>

        </div>
    );
};

export default RequestHeader;