import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';

import style from './style.module.css'

const ShareModal = ({
  visible,
  sharedMessage,
  redirectTomessage,
  redirectCountdown,
  source,
  footer,
}) => {
  const [seconds, setSeconds] = useState(5);
  const [modalVisible, setModalVisible] = useState(visible);

  useEffect(() => {
    const timeout =
      seconds > 0 &&
      setTimeout(() => {
        setSeconds(seconds - 1);
      }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [seconds]);

  const afterTime = seconds ? `${redirectCountdown} ${seconds}` : '';

  return (
    <div>
      <Modal
        closable={true}
        maskClosable={true}
        destroyOnClose={true}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        afterClose={() => setModalVisible(false)}
        footer={footer}
      >
        <div className={style.modal}>
          <span className={ style.modalTitle }>{ `${ redirectTomessage } ${ source }?` }</span>
          <span className={ style.modalSubtitle }>{ afterTime }</span>
          <span className={ style.modalMessage }>{ sharedMessage }</span></div>
      </Modal>
    </div>
  );
};

export default ShareModal;
