import React, {useEffect, useState} from 'react'; 
import LogoImg from '../../assets/images/Localyser_Logo_Primary.png';
import style from './style.module.css';

const ReviewImage =()=> {
    const [ imageUrl, setImageUrl ] = useState(null);
    useEffect(()=>{
        const pathName = window.location.pathname.split("/");
        const reviewId = pathName[2];
        const imageSize = pathName[3];
         if (imageSize && reviewId) {
          const url = `https://staging-api.localyser.com/review/${reviewId}_${imageSize}.png`;
          setImageUrl(url);
         }
    },[])

return (
    <div className={style.pageWrapper}>
        {imageUrl ?  <img src={imageUrl} alt="review" className = {style.reviewImage} />
                : <p className={style.noData}> No review image. Pleasy try late.</p>
    }
        <div>
            <a href="https://www.localyser.com/" target="_blank" rel="noopener noreferrer">
                <p className={style.poweredByLink}>Powered by <img src = {LogoImg} width="100px" height="auto" alt="logo"/></p>
            </a>
        </div>
    </div> 
)
};

export default ReviewImage;

