import React, { useState } from 'react';
import RequestHeader from './RequestHeader';
import RequestForm from './RequestForm';
import RequestFooter from './RequestFooter';
import style from './style.module.css';
import { INFORMATION_REQUEST_API } from '../../config';
import axios from 'axios';


const CustomerRequest = ({ reviewId, lables, languagesOptions }) => {
    const userLang = navigator.language || navigator.userLanguage;
    const default_language = userLang.includes('ar') ? 'ar' : 'en';
    const [language, setDefaultLanguage] = useState(default_language);
    const [showRequestForm, setShowRequestForm] = useState(true);
    const onSelectLanguage = language => {
        setDefaultLanguage(language);
    };
    const onSubmitCustomerRequest = values => {
        sendData(values)
    }
    const sendData = data => {
        axios.patch(`${INFORMATION_REQUEST_API}${reviewId}`, data).then(() => setShowRequestForm(false))
    }
    return (
        <div className={style.customerRequestWrapper}>
            <RequestHeader headerTitle={lables?.customerInformationRequest[language]} languagesOptions={languagesOptions} value={language} onSelect={onSelectLanguage} />
            <RequestForm
                requestTitle={lables?.informationRequestMessage[language]}
                onSubmitCustomerRequest={onSubmitCustomerRequest}
                firstName={lables?.firstName[language]}
                lastName={lables?.lastName[language]}
                email={lables?.userEmailPlaceholder[language]}
                mobile={lables?.userMobilePlaceholder[language]}
                requiredMessage={lables?.requiredMessage[language]}
                submitButton={lables?.giveMoreDetailsButtonWithoutFeedback[language]}
                successMessage={lables?.successMessage[language]}
                validateEmail={lables?.validateEmail[language]}
                showRequestForm={showRequestForm} 
            />
            <RequestFooter copyright={lables?.copyright ? lables.copyright[language] : 'Copyright'} powered={lables?.powered ? lables.powered[language] : 'Powered by'} />
        </div>
    );
};

export default CustomerRequest;