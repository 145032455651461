import React  from 'react';
import { Form, Input, Button, Icon } from 'antd'
import style from './style.module.css';
import SuccesfullRequest from '../SuccessfulRequest';
const regExpPhoneNumber = /^[0-9+' ']{1,}[0-9-' ']{3,15}$/;

const RequestForm = ({
    requestTitle,
    form,
    firstName,
    lastName,
    email,
    mobile,
    onSubmitCustomerRequest,
    requiredMessage,
    submitButton,
    successMessage,
    showRequestForm,
    validateEmail }) => {
    

    const onSubmit = (e) => {
        e.preventDefault();
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                onSubmitCustomerRequest(values)
            }
        });
    }

    const { getFieldDecorator, getFieldValue } = form;
    return (
        <div className={style.templateEditorWrapper}>
            <div className={style.template}>
                {showRequestForm &&
                    <>
                        <h2 className={style.formTitle}>{requestTitle}</h2>
                        <Form onSubmit={onSubmit} className={style.requestFormStyle}>
                            <Form.Item className={style.formItem}>
                                {getFieldDecorator('firstName', {
                                })(<Input
                                    placeholder={firstName}
                                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                />)}
                            </Form.Item>
                            <Form.Item className={style.formItem}>
                                {getFieldDecorator('lastName', {
                                })(<Input
                                    placeholder={lastName}
                                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                />)}
                            </Form.Item>
                            <Form.Item className={style.formItem} >
                                {getFieldDecorator('email', {
                                    rules: [
                                        {
                                            type: 'email',
                                            message: validateEmail,
                                        },
                                        {
                                            required: !getFieldValue('mobile') ? true : false,
                                            message: requiredMessage,
                                        },
                                    ],
                                })(<Input
                                    placeholder={email}
                                    prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                />)}
                            </Form.Item>
                            <Form.Item className={style.formItem}>
                                {getFieldDecorator('mobile', {
                                    rules: [
                                        {
                                            pattern: regExpPhoneNumber,
                                            message: requiredMessage
                                        }
                                    ]
                                })(<Input
                                    prefix={<Icon type="mobile" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    placeholder={mobile}
                                />)}
                            </Form.Item>
                            <Form.Item className={style.formItemSubmitButton}>
                                <Button type="primary" htmlType="submit" className={style.submitButton}>
                                    {submitButton}
                                </Button>
                            </Form.Item>
                        </Form>
                    </>}
                {!showRequestForm && <SuccesfullRequest successMessage={successMessage} />}
            </div>

        </div>
    );
};

const WrappedRequestForm = Form.create({ name: 'customerRequest' })(RequestForm);

export default WrappedRequestForm;