import React, {useState} from 'react';
import style from './style.module.css';
import { Rate } from 'antd';
import Details from '../Details';
import LocationLogoAndAddress from '../LocationLogoAndAddress';


const GiveMoreDetails = ({rateValue, companyName, companyAdress, logoUrl, sendUpdatedReviewText}) => {
    const [detailsValue, setDetailsValue] = useState('');
    const [detailsvalueError, setDetailsValueError ] = useState('');
    const [showThanksMessage, setShowMessage] = useState(false);
    const onChange  = e =>{
        const currentDatails = e.currentTarget.value;
       return  setDetailsValue(currentDatails)
    }
    const onSubmit = async (e) => {
        e.preventDefault()
        if (detailsValue) {
          await sendUpdatedReviewText(detailsValue);
          setDetailsValue('');
          setShowMessage(true);
          }
          else {
            setDetailsValueError('In order to send details, please describe your experience.')
          }
    }

    return (
        <div className = {style.giveMoreDetailswrapper} >
            <Rate value={rateValue} disabled className={style.rate} />
            <div className={style.detailsWrapper}>
              <Details
                  submitButtonTitle='Submit'
                  showThanksMessage={showThanksMessage}
                  showMoreDetails={true}
                  thanksMessage='Thank you for the additional details.'
                  detailsValue={detailsValue}
                  onChange={onChange}
                  onSubmit = {onSubmit}
                  userDetailsFeedbackTitle = {null}
                  userFeedbackPlaceholder = 'Please describe your experience'
                  submitButton='Submit'
                  detailsvalueError={detailsvalueError}/>
            </div>
              <LocationLogoAndAddress
                logoUrl={logoUrl}
                companyAdress={companyAdress}
                companyName={companyName}
             />

        </div>
    );
};

export default GiveMoreDetails;
