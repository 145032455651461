import React from 'react';
import Logo from '../../assets/images/Localyser_Logo_Primary.png';
import style from './style.module.css';

const FooterTemplate = ({ divider, classname, copyrightStyle, footerUrl, companyName, powered, copyright, feedbackType }) => {
  const date = new Date();
  const currentYear = date.getFullYear();

  return (
    <div className={style.poweredByWrapper}>
      {
        feedbackType === 'kiosk' ?
          <p className={style.poweredBy}>
            {powered} &#160;
            <a href='https://www.localyser.com' target='_blank' rel='noopener noreferrer'>
              <img src={Logo} alt='Localyser logo' width='72px' />
            </a>
          </p>
          : ''
      }
      <p className={style.copyright}>
        {copyright} &#169; {currentYear}&#160;
        {footerUrl ? (
          <a href={footerUrl} target='_blank' rel='noopener noreferrer'>
            {companyName}
          </a>
        ) : (
          companyName
        )}
      </p>
    </div>
  );
};

export default FooterTemplate;
