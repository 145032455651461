import React from 'react';
import { ReactComponent as CheckIcon } from '../../../assets/images/check-circle.svg';
import style from './style.module.css';

const SuccesfullRequest = ({ successMessage }) => {
    return (
        <div className={style.successMessageWtapper}>
            <CheckIcon stroke='fontColor' width={100} height={100} />
            <h3 className={style.successMassage}>{successMessage}</h3>
        </div>
    );
};

export default SuccesfullRequest;