import React from 'react';
import { Select } from 'antd';
import style from './style.module.css';
import ISO6391 from 'iso-639-1';
import PropTypes from 'prop-types';

const Option = Select.Option;

const LanguageFilter = ({ onSelect, value, languagesOptions }) => {
    const languagesForSelection = languagesOptions.map(langCode=>({value:langCode, title:ISO6391.getNativeName(langCode)}));
    return (
        <div id="area">
            <Select
                value={value}
                className={style.filterLanguage}
                onSelect={onSelect}
                getPopupContainer={() => document.getElementById('area')}
            >
                {languagesForSelection.map(option => (
                    <Option key={option.value} value={option.value} className={style.languageSelector} >
                        {option.title}
                    </Option>
                ))}
            </Select>
        </div>
    );
};

LanguageFilter.propTypes = {
    value: PropTypes.string.isRequired,
    onSelect: PropTypes.func,
    languagesOptions: PropTypes.array.isRequired,
};

export default LanguageFilter;