// const isLocalhost = /localhost/i.test(document.location.hostname);
// const isStaging = /staging/i.test(document.location.hostname);
// const apiUrlFromLocationMatched = /API_URL=([^&]+)/.exec(document.location.search);
// const apiUrlFromLocation = apiUrlFromLocationMatched && apiUrlFromLocationMatched[1];
//
// const STAGING_API_URL = 'https://staging-api.localyser.com/';
// const PRODUCTION_API_URL = 'https://api.localyser.com/';
//
// const API_URL = apiUrlFromLocation || (isLocalhost || isStaging ? STAGING_API_URL : PRODUCTION_API_URL);

const API_URL = process.env.REACT_APP_API_URL || 'https://staging-api.localyser.com/';
const isLOCALHOST = process.env.REACT_APP_NODE_ENV === 'localhost';
const INFORMATION_REQUEST_API = `${API_URL}v2/api/reviews/info/`;
// const API_LOCALHOST = 'http://localhost:3000/';
// const API_STAGING = 'https://staging.fdbck.me/';
// const API_PRODUCTION = 'https://fdbck.me/';
// const API_REFFERENCE = apiUrlFromLocation || (isLocalhost ? API_LOCALHOST : isStaging ? API_STAGING : API_PRODUCTION );

const API_REFFERENCE = process.env.REACT_APP_API_REFFERENCE || 'https://staging.fdbck.me/';

export {
    API_URL,
    API_REFFERENCE,
    INFORMATION_REQUEST_API,
    isLOCALHOST
};
