import React from 'react';
import style from './style.module.css';
import classnames from 'classnames';

const Details = ({
  userDetailsFeedbackTitle,
  userFeedbackPlaceholder,
  detailsValue,
  submitButtonTitle,
  onChange,
  onSubmit,
  detailsvalueError,
  fontColor,
  showMoreDetails,
  thanksMessage,
  showThanksMessage,
}) => {
  return (
    <>
      {showMoreDetails &&
        (!showThanksMessage ? (
          <form onSubmit={onSubmit}>
            {userDetailsFeedbackTitle && (
              <p style={{ color: fontColor }}>{userDetailsFeedbackTitle}</p>
            )}
            <textarea
              className={classnames(
                style.textarea,
                detailsvalueError && style.textareaError
              )}
              type='textarea'
              placeholder={userFeedbackPlaceholder}
              onChange={onChange}
              value={detailsValue}
            ></textarea>
            {detailsvalueError && (
              <p className={style.textAreaError}>{detailsvalueError}</p>
            )}
            <p>
              <button typy='submit' className={style.submitButton}>
                {submitButtonTitle}
              </button>
            </p>
          </form>
        ) : (
          <p style={{ color: fontColor, marginTop: '15px', fontSize: '18px' }}>
            {thanksMessage}
          </p>
        ))}
    </>
  );
};

export default Details;
