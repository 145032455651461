import React from 'react';
import Logo from '../../../assets/images/Localyser_Brand_Icon.png';
import style from './style.module.css';

const date = new Date();
const currentYear = date.getFullYear();

const RequestFooter = ({ copyright, powered }) => {
    return (
        <>
            <div className={style.poweredByWrapper}>
                <span className={style.copyrightStyle}>{copyright} &#169; {currentYear}&#160;</span>
                <span>{powered} &#160;
                    <img src={Logo} alt='Localyser logo' width='72px' />
                </span>
            </div>
        </>
    );
};

export default RequestFooter;