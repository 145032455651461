import React from 'react';
import style from './style.module.css';
import SelectLocation from '../SelectLocation';
import LocationLogoAndAddress from '../LocationLogoAndAddress';

const NearestLocationNotFound = ({
    logoUrl,
    locationsOptions,
    locationValue,
    onSelectLocation,
    backgroundColor,
    dontFoundBrandMessage,
    fontColor
    }) => {

    return (
        <div className = {style.templateEditorWrapper}>
            <div className = {style.template} style={{backgroundColor: backgroundColor}}>
                <div className={style.locationInfoWrapper}>
                    <LocationLogoAndAddress
                        logoUrl={logoUrl}
                    />
                    <div classsName = {style.selectLocationWrapper}>
                    <p className={style.chooseRightLocation} style={{color: fontColor}}>{dontFoundBrandMessage ? dontFoundBrandMessage :'Please select your location'}</p>
                        <SelectLocation options={locationsOptions} value={locationValue} onSelect={onSelectLocation} fontColor={fontColor}/>
                    </div>
                </div>
            </div>
        </div>
      
    );
};

export default NearestLocationNotFound;
