import React from 'react';
import style from './style.module.css';
import PropTypes from 'prop-types';
import LanguageFilter from '../LanguageFilter';


const HeaderTemplate = ({pageTitle, language, onSelectLanguage, languagesOptions, showDetailsPage, feedbackType}) => {
    return (
        <div style={{backgroundColor: feedbackType !== 'kiosk' ? '#1a91da' : 'transparent'}}>
            <div className={`${style.headerTemplateWrapper} ${feedbackType === 'kiosk' ? style.kioskHeaderTemplateWrapper : ''}`}>
              {feedbackType !== 'kiosk' ? <p data-name='pageTitle' className={style.pageTitle}>{pageTitle}</p> : ''}

              {!showDetailsPage && <LanguageFilter value={language} onSelect={onSelectLanguage} languagesOptions={languagesOptions} feedbackType={feedbackType}/>}
            </div>
        </div>
    );
};
HeaderTemplate.propTypes = {
    language: PropTypes.string.isRequired,
    pageTitle: PropTypes.string,
    onSelectLanguage: PropTypes.func.isRequired,
    languagesOptions: PropTypes.array.isRequired,
};

export default HeaderTemplate;

